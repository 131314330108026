import { useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import ModalAdvanced from "../../components/ModalAdvanced/ModalAdvanced";
import { useLang } from "../../language";
import { MenuList } from "../../components/MenuList/MenuList";
import './ModalTecnicos.scss'
import { openLinkByClickConvenios } from "../../helpers/helpers";
import { orderBy } from "lodash";

export const ModalTecnicos = ({ data, handleClose }: any) => {

    const Lang = useLang()

    const [active, setActive] = useState('andalucia');

    let navLinks: any = [
        {
            id: 'catalunia',
            title: <span>{Lang('CATALUNIA')}</span>,
        },
        {
            id: 'galicia',
            title: <span>{Lang('GALICIA')}</span>,
        },
        {
            id: 'islas-baleares',
            title: <span>{Lang('ISLAS_BALEARES')}</span>,
        },
        {
            id: 'navarra',
            title: <span>{Lang('NAVARRA')}</span>,
        },
        {
            id: 'pais-vasco',
            title: <span>{Lang('PAIS_VASCO')}</span>,
        },
        {
            id: 'castilla-leon',
            title: <span>{Lang('CASTILLA_Y_LEON')}</span>,
        },
        {
            id: 'canarias',
            title: <span>{Lang('CANARIAS')}</span>,
        },
        {
            id: 'andalucia',
            title: <span>{Lang('ANDALUCIA')}</span>,
        },
    ]

    navLinks = orderBy(navLinks, ['id'])

    const dataTecnicos = [
        {
            group: 'catalunia',
            titulo: "BARCELONA",
            src: `${process.env.PUBLIC_URL}/images/logos/logo2.webp`,
            nombre: "Col·legi de l’Arquitectura Tècnica de Barcelona",
            email: "treball@cateb.cat",
            movil: "93 240 20 60 (extensión 3)"
        },
        {
            group: 'catalunia',
            titulo: "GIRONA",
            src: `${process.env.PUBLIC_URL}/images/logos/logo18.jpg`,
            nombre: "Col·legi de l'Arquitectura Tècnica de Girona",
            email: "visats2@aparelladors.cat",
            movil: "972 211 854"
        },
        {
            group: 'catalunia',
            titulo: "LLEIDA",
            src: `${process.env.PUBLIC_URL}/images/logos/logo8.png`,
            nombre: "Col·legi de l'Arquitectura Tècnica de Lleida",
            email: "obresengarantia@caatlleida.net",
            movil: "973 24 91 00"
        },
        {
            group: 'catalunia',
            titulo: "TARRAGONA",
            src: `${process.env.PUBLIC_URL}/images/logos/logo3.png`,
            nombre: "Col·legi d'Aparelladors, Arquitectes Tècnics i Enginyers d'Edificació de Tarragona",
            web: "www.obresambgarantia.com",
            email: "gabtec@apatgn.org",
            movil: "977 21 27 99"
        },
        {
            group: 'catalunia',
            titulo: "TERRES DE L’EBRE",
            src: `${process.env.PUBLIC_URL}/images/logos/logo9.jpg`,
            nombre: "Col·legi de l’Arquitectura Tècnica de les Terres de l’Ebre",
            email: "administracio@catebre.cat",
            movil: "977 44 37 80"
        },
        {
            group: 'galicia',
            titulo: "A CORUÑA",
            src: `${process.env.PUBLIC_URL}/images/logos/logo7.jpg`,
            nombre: "Colegio Oficial de la Arquitectura Técnica de A Coruña",
            web: "www.coatac.es/servicios-al-ciudadano",
            movil: "981 206 214 (extensión 2111)"
        },
        {
            group: 'galicia',
            titulo: "LUGO",
            src: `${process.env.PUBLIC_URL}/images/logos/logo10.png`,
            nombre: "Colegio Oficial de la Arquitectura Técnica de Lugo",
            email: "correo@coaatlugo.com",
            movil: "982 23 10 11"
        },
        {
            group: 'galicia',
            titulo: "PONTEVENDRA",
            src: `${process.env.PUBLIC_URL}/images/logos/logo12.png`,
            nombre: "Colegio Oficial de la Arquitectura Técnica de Pontevedra",
            email: "direccion@coatpo.es",
            movil: "986 434 066"
        },
        {
            group: 'galicia',
            titulo: "OURENSE",
            src: `${process.env.PUBLIC_URL}/images/logos/logo11.png`,
            nombre: "Colegio Oficial de la Arquitectura Técnica de Ourense",
            email: "visado@coatourense.es",
            movil: "988 372 600"
        },
        {
            group: 'islas-baleares',
            titulo: "MALLORCA",
            src: `${process.env.PUBLIC_URL}/images/logos/logo1.webp`,
            nombre: "Col·legi Oficial d'Aparelladors i Arquitectes Tècnics de Mallorca",
            web: "www.obresambgarantia.com",
            email: "secretariatecnica@coaatmca.com",
            movil: "971 452 461"
        },
        {
            group: 'islas-baleares',
            titulo: "MENORCA",
            src: `${process.env.PUBLIC_URL}/images/logos/logo5.jpg`,
            nombre: "Col·legi Oficial d'Aparelladors, Arquitectes Tècnics i Enginyers d'Edificació de Menorca",
            email: "coaatmenorca@coaatmenorca.com",
            movil: "971 36 34 95"
        },
        {
            group: 'navarra',
            titulo: "NAVARRA",
            src: `${process.env.PUBLIC_URL}/images/logos/logo4.jpg`,
            nombre: "Colegio Oficial de la Arquitectura Técnica de Navarra",
            email: "gabinete@coaatnavarra.org",
            movil: "948 22 95 15"
        },
        {
            group: 'pais-vasco',
            titulo: "Gipuzkoa",
            src: `${process.env.PUBLIC_URL}/images/logos/logo13.jpg`,
            nombre: "Colegio Oficial de Aparejadores y Arquitectos Técnicos de Gipuzkoa",
            email: "gabinete@coaatg.org",
            movil: "943 45 80 44"
        },
        {
            group: 'castilla-leon',
            titulo: "León",
            src: `${process.env.PUBLIC_URL}/images/logos/logo14.png`,
            nombre: "Colegio Oficial de Aparejadores y Arquitectos Técnicos de León",
            email: "colegio@coaatleon.es",
            movil: "987 206 198"
        },
        {
            group: 'castilla-leon',
            titulo: "Salamanca",
            src: `${process.env.PUBLIC_URL}/images/logos/logo16.png`,
            nombre: "Colegio Oficial de Aparejadores y Arquitectos Técnicos de Salamanca",
            email: "info@coaatsa.org",
            movil: "923 26 09 77"
        },
        {
            group: 'castilla-leon',
            titulo: "Zamora",
            src: `${process.env.PUBLIC_URL}/images/logos/logo19.png`,
            nombre: "Colegio Oficial de Aparejadores y Arquitectos Técnicos de Zamora",
            email: "colegio@coaatza.org",
            movil: "980 52 37 12"
        },
        {
            group: 'canarias',
            titulo: "Lanzarote",
            src: `${process.env.PUBLIC_URL}/images/logos/logo15.png`,
            nombre: "Colegio Oficial de Aparejadores y Arquitectos Técnicos de Lanzarote",
            email: "info@coaatlanz.org",
            movil: "928 81 51 92"
        },
        {
            group: 'andalucia',
            titulo: "Málaga",
            src: `${process.env.PUBLIC_URL}/images/logos/logo17.png`,
            nombre: "Colegio Oficial de Aparejadores y Arquitectos Técnicos de Málaga",
            web: "https://www.coaat.es/conocenos/que-podemos-hacer-por-ti/particulares/designacion-de-un-tecnico-colegiado/",
            movil: "952 225 180"
        },
    ]


    return (
        <>
            <ModalAdvanced data={data} handleClose={handleClose} size='xl' className='bg-fafafa'>
                <ModalAdvanced.Head>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body>

                    <Typography variant="h6" component="h2" className='fw-bold text-center mb-3 mt-2'>
                        {Lang('CONTACTA_TECNICOS_ESPECIALISTAS_LOCALIZACION')}
                    </Typography>

                    <Grid container spacing={0} className='pt-4'>

                        <Grid item md={12} lg={3} className=''>

                            <MenuList
                                navLinks={navLinks}
                                setActive={setActive}
                                active={active}
                            />

                        </Grid>

                        <Grid item md={12} lg={9} className=''>

                            <Grid container spacing={2} className='animate__animated animate__fadeIn animate__faster'>
                                {
                                    dataTecnicos
                                        ?.filter((item: any) => item.group === active)
                                        ?.map((item2: any, index2: number) => {
                                            return (
                                                <CardTecnicoEspecialista item={item2} key={index2} />
                                            )
                                        })
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                </ModalAdvanced.Body>

                <ModalAdvanced.Footer>
                    <div className="d-flex">
                        <p>
                            {Lang('P_NO_COMUNIDAD_AUTONOMA_CONTACTE')}
                        </p>
                    </div>
                </ModalAdvanced.Footer>

            </ModalAdvanced >

        </>
    )
};

const CardTecnicoEspecialista = ({ item }: any) => {

    return (
        <>
            <Grid item xs={12} md={6} lg={4} className="">
                <Card className="h-100">
                    <CardContent>

                        <h3 className="text-center mt-0 text-uppercase">
                            {item.titulo}
                        </h3>
                        <div className="d-flex align-items-center" style={{ height: '80px' }}>
                            <img src={item.src} alt="" className="CardTecnicoEspecialista__img" />
                        </div>
                        <p className="my-1">
                            <small>{item.nombre}</small>
                        </p>
                        {
                            item.web &&
                            <p className="overflow-hidden color-primary my-1" style={{textOverflow: 'ellipsis'}}>
                                <small>
                                    <span className="link-primary text-decoration-underline" onClick={() => openLinkByClickConvenios(item.web)}>{item.web}</span>
                                </small>
                            </p>
                        }

                        {
                            item.email &&
                            <p className="overflow-hidden color-primary my-1"  style={{textOverflow: 'ellipsis'}}>
                                <small>
                                    <a className="link-primary text-decoration-underline" href={`mailto:${item.email}`}>{item.email}</a>
                                </small>
                            </p>
                        }

                        <p className="mb-0 mt-1">
                            <small>{item.movil}</small>
                        </p>

                    </CardContent>
                </Card>
            </Grid>
        </>
    )
};
